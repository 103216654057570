import React from "react"
import cx from "classnames"
import I18nContext from "../common/i18nContext"
import { getContent } from "../common/content"

const style = require("./services.module.scss")

const services = getContent().services

// const getContent = (content) => ({
//   __html: content,
// })

const Services = () => (
  <I18nContext.Consumer>
    {({ setLanguage, lang }) => (
      <section className={style.Services} id={"uslugi"}>
        <div
          className={cx("container")}
        >
          <div className={cx("row justify-content-center align-content-center")}>
            <div className={cx(style.Content, "col-12")}>
              <h2>
                {services[lang].title}
              </h2>

              <div className={"row align-items-stretch"}>
                {services[lang].content.map(service => (<div className={cx("col-12 col-md-4", style.Service)}>

                  <div className={style.ServiceContent}>
                    <div className={style.ImgWrapper}>
                      <img src={require(`../images/services/${service.img}`)} alt={service.name}/>
                    </div>
                    <div className={style.ServiceDesc}>
                      <h3>{service.name}</h3>
                      <p>{service.desc}</p>
                    </div>
                  </div>
                </div>))}
              </div>
            </div>
          </div>
        </div>
      </section>)}
  </I18nContext.Consumer>
)

export default Services

import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import About from "../components/about"
import Services from "../components/services"
import Contact from "../components/contact"

const IndexPage = () => (
  <Layout>
    <SEO title={'HALK LOGY'} lang={'pl'}/>
    <Banner/>
    <About/>
    <Services/>
    <Contact/>
  </Layout>
)

export default IndexPage

import React from "react"
import cx from "classnames"
import I18nContext from "../common/i18nContext"
import { getContent } from "../common/content"

const content = getContent().banner
const style = require("./banner.module.scss")

const Banner = () => (
  <I18nContext.Consumer>
    {({ lang }) => (
      <section className={style.Banner}>
        <div
          className={cx("container")}
        >
          <div className={cx("row justify-content-center align-content-center")}>
            <div className={cx(style.Content, "col-12 col-sm-8")}>
              <h2>
                {content[lang]}
              </h2>
            </div>
          </div>
        </div>
      </section>)}
  </I18nContext.Consumer>
)

export default Banner

import React from "react"
import cx from "classnames"
import I18nContext from "../common/i18nContext"
import { getContent } from "../common/content"

const style = require("./about.module.scss")
const content = getContent().about
const About = () => (
  <I18nContext.Consumer>
      {({ lang }) => (
      <section className={style.About} id={"o-firmie"}>
        <div
          className={cx("container")}
        >
          <div className={cx("row justify-content-center align-content-center")}>
            <div className={cx(style.Content, "col-12 col-sm-8")}>
              <h2>
                {content[lang].title}
              </h2>
              {content[lang].content}
            </div>
          </div>
        </div>
      </section>)
    }
  </I18nContext.Consumer>
)

export default About

import React from "react"
import cx from "classnames"
import { Email, Phone, Map } from "@material-ui/icons"
import MapImage from "../images/map.png"
import I18nContext from "../common/i18nContext"
import { getContent } from "../common/content"

const style = require("./contact.module.scss")

const contactData = {
  phone: [{
    number: "+48 538 546 506",
  },
  ],
  mail: [
    {
      email: "biuro@halk-logy.pl",
    }, {
      email: "info@halk-logy.pl",
    },
  ],
  address: ["HALK LOGY", "27-415 Kunów", "Nietulisko Małe 121A", "Oddział : 27-400 Ostrowiec Św.",  "Aleja Solidarności 11", "NIP: 6642036617", "REGON: 301671550"],
}

const content = getContent().contact

const Contact = () => {

  const [status, setStatus] = React.useState("")

  const submitForm = (ev) => {
    ev.preventDefault()
    const form = ev.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        setStatus("SUCCESS")
      } else {
        setStatus("ERROR")
      }
    }
    xhr.send(data)
  }
  return (
    <I18nContext.Consumer>
      {({ lang }) => (
        <section className={style.Contact} id={"kontakt"}>
          <div
            className={cx("container")}
          >
            <div className={cx("row justify-content-center align-content-center")}>
              <div className={cx(style.Content, "col-12")}>
                <h2>
                  {content[lang].title}
                </h2>

                <div className={"row "}>
                  <div className={cx("col-12 col-md-4", style.Item)}>
                    <Email className={style.Icon}/>
                    {contactData.mail.map(m => (
                      <p>{m.name && <strong>{m.name}:</strong>} {m.email}</p>
                    ))}
                  </div>
                  <div className={cx("col-12 col-md-4", style.Item)}>
                    <Map className={style.Icon}/>
                    {contactData.address.map(a => (
                      <p>{a}</p>
                    ))}
                  </div>
                  <div className={cx("col-12 col-md-4", style.Item)}>
                    <Phone className={style.Icon}/>
                    {contactData.phone.map(p => (
                      <p>{p.number}</p>
                    ))}
                  </div>
                </div>
                {/*<div className={"row"}>*/}
                {/*<div className={"col-12", style.Map}>*/}
                {/*<img src={MapImage} alt={contactData.address.join(", ")}/>*/}
                {/*<div className={style.Overlay}/>*/}
                {/*</div>*/}
                {/*</div>*/}

                <div className={"row justify-content-center"}>
                  <div className={"col-8"}>
                    <form action="https://formspree.io/mzneoaem" onSubmit={submitForm} method="POST"
                          className={style.ContactForm}>
                      <div className={"row"}>
                        <div className={"col-6"}>
                          <input type={"text"} name={"name"} placeholder={content[lang].form.name}/>
                        </div>
                        <div className={"col-6"}>
                          <input type={"email"} name={"email"} placeholder={content[lang].form.email}/>
                        </div>
                        <div className={"col-12"}>
                          <textarea type={"text"} name={"message"} placeholder={content[lang].form.message}/>
                        </div>
                        {!status && <div className={"col-12 d-flex justify-content-end"}>
                          <button type={"submit"} className={style.SubmitBtn}>{content[lang].form.send}</button>
                        </div>}
                        {status && <div className={"col-12 d-flex justify-content-center mt-3"}>
                          {status === "SUCCESS" && <p>{content[lang].form.successMessage}</p>}
                          {status === "ERROR" && <p>{content[lang].form.errorMessage}</p>}
                        </div>}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>)}
    </I18nContext.Consumer>
  )
}

export default Contact
